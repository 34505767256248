<template>
  <div>
    <mdb-row class="justify-content-center"> </mdb-row>
    <mdb-row class="justify-content-center">
      <mdb-col col="7">
        <mdb-btn class="mt-3 mb-3" :style="color" block @click="handleSend">{{
          vendorData.recommended_join
        }}</mdb-btn>
      </mdb-col>
    </mdb-row>
  </div>
</template>
<script>
import { mdbRow, mdbCol, mdbBtn } from "mdbvue";
import helper from "@/mixins/helper";
import api from "@/mixins/api";
export default {
  name: "TerveydenhoitajaliittoRecommended",
  mixins: [helper, api],
  components: {
    mdbRow,
    mdbCol,
    mdbBtn,
  },
  props: {
    vendorData: Object,
    transactionData: Object,
  },
  data() {
    return {
      transactionDataLocal: this.transactionData,
      localVendorData: this.vendorData,
      color: { "background-color": this.vendorData.color },
      txObject: {
        transactionId: this.transactionData.id,
        email: this.transactionData.recommended,
        recommendedId: this.transactionData.recommendedId,
        surrogate: this.transactionData.recommendersurrogate,
        firstname: "",
        lastname: "",
        image: this.vendorData.recommended_thankyou_image,
        subject: this.vendorData.recommended_thankyou_subject,
        text: this.vendorData.recommended_thankyou,
      },
    };
  },
  methods: {
    handleSend() {
      this.sendTransactionUpdate();
      this.$notify.success({
        message: this.$t("basicform.form_moving_external_form"),
        position: "top right",
        timeOut: this.$store.state.notifytimeoutshort,
      });
      setTimeout(
        function () {
          window.open(
            this.localVendorData.recommended_form_link +
              "&transactionId=" +
              this.txObject.transactionId +
              "&recommendedId=" +
              this.txObject.recommendedId +
              "&recommendersurrogate=" +
              this.txObject.surrogate
          );
        }.bind(this),
        1000
      );
    },
    async sendTransactionUpdate() {
      this.clickTransaction(this.txObject).then((res) => {
        switch (res) {
          case 200:
            break;
        }
      });
    },
  },
  mounted() {},
};
</script>
<style scoped>
.md-form.md-outline {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}
</style>
